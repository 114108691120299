<template>
  <div>
    <sweet-modal ref="deleteAlert">
      <p class="modal-text">
        {{ $t('component.cpTableDeleteModal.message', [itemDescription]) }}
      </p>
      <div class="btn-container">
        <b-button
          variant="success"
          @click="deleteItem"
        >
          {{ $t('common.button.ok') }}
        </b-button>
        <b-button
          variant="primary"
          @click="$refs.deleteAlert.close()"
        >
          {{ $t('common.button.cancel') }}
        </b-button>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: 'CpTableDeleteModal',
  components: {
    SweetModal,
  },
  props: {
    itemInfo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    itemDescription() {
      if (this.itemInfo) {
        const { name, question, address } = this.itemInfo;
        return name || question || address;
      }
      return 'item';
    },
  },
  methods: {
    deleteItem() {
      this.$emit('delete-item');
    },
  },
};
</script>

<style lang="scss" scoped>
  .btn-container {
    width: 30%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
  }

  .modal-text {
    white-space: pre-line;
  }
</style>
